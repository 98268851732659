<template>
  <div class="home">
    <h1>弼马智能</h1>
    <h2>路径分析</h2>
    <p>潘川路罗东路 至 海亭路华驿路</p>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
